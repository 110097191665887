import React from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  useGridRootProps,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { TextInput } from "@mantine/core";
// import PrintIcon from "../../../assets/print.svg";
import columnIcon from "../../assets/columns.svg";
import filtersIcon from "../../assets/filter.svg";
import downloadIcon from "../../assets/download.svg";
import densityIcon from "../../assets/density.svg";
import { IconButton } from "@mui/material";
import { Search } from "tabler-icons-react";
import { useAuthUser } from "react-auth-kit";

const Table = ({
  data,
  columns,
  isLoading,
  onPageSizeChange,
  onPageChange,
  searchQuery,
  handleChange,
  keys,
  disableFilters,
  removeActionButton,
  removeSearch,
  page,
  pageSize,
  role,
  title,
  fetchData,
  onFilterChange,
  rowCount,
  actionButton,
  handleActionButton,
  onRowSelectionModelChange,
  rowSelectionModel,
  disableToolbar,
  hidePagination = false,
}) => {
  const auth = useAuthUser();
  const GridCustomToolbar = React.forwardRef(function GridToolbar(props, ref) {
    const { className, ...other } = props;
    const rootProps = useGridRootProps();

    if (
      (rootProps.disableColumnFilter &&
        rootProps.disableColumnSelector &&
        rootProps.disableDensitySelector) ||
      disableToolbar
    ) {
      return null;
    }

    return (
      <>
        <GridToolbarContainer className="flex " ref={ref} {...other}>
          <div className="flex justify-end gap-[5px] items-center w-1/2 mx-2 ">
            <div className="flex gap-[5px] ">
              <GridToolbarColumnsButton
                startIcon={<img className="w-3 h-4 " src={columnIcon} alt="Column" />}
              />
              <GridToolbarFilterButton
                componentsProps={{
                  button: {
                    startIcon: (
                      <IconButton sx={{ backgroundColor: "#f8f9fa !important" }}>
                        <img className="w-3 h-4 " src={filtersIcon} alt="filter icon" />
                      </IconButton>
                    ),
                  },
                }}
              />
              <GridToolbarExport
                startIcon={<img className="w-3 h-4 " src={downloadIcon} alt="Download " />}
              />
              <GridToolbarDensitySelector
                startIcon={<img className="w-3 h-4 " src={densityIcon} alt="Column" />}
              />
            </div>
            {/* {(auth().role === "Experience Officer" && role) || removeActionButton ? null : (
              <button onClick={() => handleActionButton()} className="bg-black h-[60px] px-2 text-[16px] font-extrabold min-w-[100px] rounded-[10px] text-white">
                {actionButton}
              </button>
            )} */}
            {removeActionButton
              ? null
              : auth().role !== "Land Lord" && (
                  <button
                    onClick={() => handleActionButton()}
                    className="bg-black h-[60px] px-2 text-[16px] font-extrabold min-w-[100px] rounded-[10px] text-white"
                  >
                    {actionButton}
                  </button>
                )}
          </div>
        </GridToolbarContainer>
      </>
    );
  });

  // const search = (data) => {
  //   return data?.filter((item) =>
  //     keys?.some((key) =>
  //       item[key]?.toString().toLowerCase().includes(searchQuery.toLowerCase())
  //     )
  //   );
  // };

  return (
    <div className=" bg-[#f8f9fa] relative  mt-0">
      {!removeSearch && (
        <div>
          <TextInput
            style={{ backgroundColor: "#f8f9fa !important" }}
            label=""
            placeholder="Search..."
            radius="md"
            size="lg"
            className="absolute w-[40%]   bg-[#f8f9fa]  top-3  left-[0%] z-50"
            value={searchQuery}
            onChange={handleChange}
            icon={<Search size={24} />}
          />
        </div>
      )}

      <DataGrid
        getRowHeight={() => "auto"}
        sx={{
          boxShadow: 0,
          border: 0,
          width: "100%",
          borderColor: "white",
          paddingTop: "10px",
          backgroundColor: "#f8f9fa",

          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
            // padding: "1px",
          },
          "& .MuiDataGrid-footerContainer ": {
            backgroundColor: "white",
            paddingBottom: "10px",
            borderRadius: " 0 0 10px 0",
          },

          "& .MuiButtonBase-root": {
            padding: 0,
            margin: 0,
          },
          borderTop: "none",
          "& .MuiDataGrid-root": {
            marginTop: "0 !important",
          },
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiButton-text": {
            color: "rgba(0, 0, 0, 0.54) !important",
          },
          " & .MuiDataGrid-root": {
            backgroundColor: "white !important",
          },
          "& .MuiDataGrid-main": {
            background: "white",
            marginTop: "20px",
            width: "100%",
            borderRadius: "10px",
          },
          "& .MuiDataGrid-toolbarContainer": {
            display: " flex ",
            flexDirection: "row",
            position: "relative",
            justifyContent: "flex-end",
            backgroundColor: "#f8f9fa",
            padding: "5px 0 ",
          },

          "& .MuiButton-root": {
            padding: "8px",
            backgroundColor: "#f8f9fa !important",
            border: "1px solid #DEDEDE",
            margin: "0 5px",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "60px",
            width: "70px",
            fontSize: "11px",
            marginBottom: "0px",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "1.5rem",
          },
          "& .MuiDataGrid-columnHeaders": {
            fontSize: "18px",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "600",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "16px",
          },
          "& .MuiIconButton-root": {
            height: "28px",
            width: "32px",
          },
        }}
        disableColumnFilter={disableFilters}
        autoHeight
        rows={data}
        rowCount={rowCount}
        loading={isLoading}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        pagination
        hideFooterPagination={hidePagination}
        disableSelectionOnClick
        checkboxSelection
        onRowSelectionModelChange={onRowSelectionModelChange}
        rowSelectionModel={rowSelectionModel}
        responsive
        page={page}
        pageSize={pageSize}
        density="comfortable"
        paginationMode="server"
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        columns={columns}
        components={{ Toolbar: GridCustomToolbar }}
        componentsProps={{}}
        getRowId={(row) => row?.id || row?._id}
      />
    </div>
  );
};

export default Table;
